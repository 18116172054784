import BlockContent from '@sanity/block-content-to-react';
import {Link as LinkType} from '@data/types';
import Link from '@components/Link';
import {color} from '@design/templateFns';
import styled from 'styled-components';

import {PtAnchor, PtEmbed, PtImage, PtFile, PtFloatBreak} from './';
import {ProseContent} from '@data/types';
import stylers from './stylers';
import RenderList from './RenderList';

import {mapLink} from '@util/mapping';

interface propsSpan {
  children: React.ReactNode;
}

interface propsLink extends propsSpan {
  mark: {
    link: LinkType;
  };
}

const StyledLink = styled(Link)`
  color: ${color('link')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:hover,
    &:visited,
    &:active {
      color: ${color('link')};
    }
  }
`;

const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node;

  if (stylers[style]) {
    const Component = stylers[style];
    return <Component {...props} />;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const serializers = {
  marks: {
    sub: ({children}: propsSpan) => <sub>{children}</sub>,
    sup: ({children}: propsSpan) => <sup>{children}</sup>,
    link: ({children, mark}: propsLink) => {
      const url = mapLink(mark.link);
      return <StyledLink href={url}>{children}</StyledLink>;
    },
  },
  list: RenderList,
  types: {
    block: BlockRenderer,
    ptAnchor: PtAnchor,
    ptImage: PtImage,
    ptFile: PtFile,
    ptEmbed: PtEmbed,
    ptFloatBreak: PtFloatBreak,
  },
};

// TODO: provide an intentional means to override styling
const RenderProseContent = ({content}: {content: ProseContent}) => (
  <BlockContent {...{blocks: content || [], serializers}} />
);

export default RenderProseContent;

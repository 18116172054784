import {ResolvedImageData} from '@data/types';
import NextImage, {ImageProps} from 'next/image';
import {publicClient as client} from '@data/sanityClient'; // TODO: preview?
import {useNextSanityImage} from 'next-sanity-image';
import log from '@util/logging';

import theme from '@theme'; // TODO: get theme via provider instead

type ArcImgProps = {
  imageData?: ResolvedImageData; // TODO: accept stack for art direction: ;
  sizes?: Stack<string>;
} & Partial<Omit<ImageProps, 'sizes'>>;

type Stack<T> = T | (T | null)[];

// TODO: cover this in tests, should be easy
export const buildSizesString = (sizes?: Stack<string | null>) => {
  if (!sizes) {
    return null;
  }
  // normalize single entries to single element arrays
  const sizeArr = (Array.isArray(sizes) ? sizes : [sizes]).slice(
    0,
    theme.breakpoints.length + 1,
  );
  // map larger breakpoints, which are specified by a query
  const breakpointSizes = [...theme.breakpoints]
    .map((breakpoint, i) => {
      const size = sizeArr[i + 1];
      if (size) {
        return `(min-width: ${breakpoint}) ${size}`;
      }
    })
    // parse out empty sizes
    .filter((a) => !!a);
  const defaultSize = sizeArr[0];

  // now map smallest size as default.
  if (defaultSize) {
    breakpointSizes.push(`${defaultSize}`);
  }
  return breakpointSizes.join(', ');
};

const ArcImg = ({imageData, sizes, ...props}: ArcImgProps) => {
  if (!imageData) {
    log.warn('ArcImg: missing imageData prop');
    return null;
  }
  const sizeStr = buildSizesString(sizes);
  const sanityProps = useNextSanityImage(client, imageData);
  if (!sanityProps) {
    log.warn('ArcImg: could not prcess imageData', {imageData});
    return null;
  }
  return (
    <NextImage
      src={sanityProps.src}
      loader={sanityProps.loader}
      layout="fill"
      objectFit="cover" // TODO: stack and fn(imageData)
      sizes={sizeStr} // null when no sizes prop passed
      {...props}
    />
  );
};

export default ArcImg;

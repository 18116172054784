import styled from 'styled-components';
import {mq} from '@design/query';
import theme from '@design/theme';
import {SimpleComponent} from 'sanity/components/utilityTypes';

/** Just something to soak up unused vertical splace.
 */
export default styled.div`
  width: 100%;
  flex-grow: 1;
` as SimpleComponent<{}>;

import React from 'react';
import Block from '@components/Layout/Block';
import {fontStack} from '@design/templateFns';
import {mq} from '@design/query';
import styled from 'styled-components';
import HTMetalAF from 'dangerously-set-html-content';
import type GenericEmbedBlockData from '@data/blocks/GenericEmbedBlockData';
import type {GenericEmbedProps} from '@data/blocks/GenericEmbedBlockData';

const Caption = styled.div`
  ${fontStack([
    'body-sm-regular',
  ])} ////////////// #font-check TODO: check this against specs (got rid of body-xs font) ~awt
  ${mq({margin: ['12px 0', '24px 0']})}
`;

const YtLargeEmbedWrapper = styled.div`
  width: 80%;
  left: 10%;
  position: relative;
  & > div {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; // TODO: AR config
    height: 0;
    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.001);
    }
  }
  // fix stupid youtube 4:3 AR thumbnail bug
  .ytp-cued-thumbnail-overlay-image {
    transform: scale(1.111);
  }
`;

const YtLargeCaptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// temporary workaround to https://github.com/christo-pr/dangerously-set-html-content/issues/4
const Danger = HTMetalAF as any;

export const UnwrappedGenericEmbed = ({markup, caption}: GenericEmbedProps) => (
  <React.Fragment>
    <Danger html={markup} />
    {caption && <Caption>{caption}</Caption>}
  </React.Fragment>
);

const GenericEmbed = (props: GenericEmbedProps) => {
  if (props.variant === 'yt_large') {
    return (
      <Block>
        <YtLargeEmbedWrapper>
          <UnwrappedGenericEmbed {...{...props, caption: undefined}} />
        </YtLargeEmbedWrapper>
        {props.caption && (
          <YtLargeCaptionWrapper>
            <Caption>{props.caption}</Caption>
          </YtLargeCaptionWrapper>
        )}
      </Block>
    );
  }
  return (
    <Block>
      <UnwrappedGenericEmbed {...props} />
    </Block>
  );
};

export const _map = (block: GenericEmbedBlockData, i) => (
  <GenericEmbed key={block._key || `${i}`} {...block} />
);

export default GenericEmbed;

import {AsyncWalkBuilder} from 'walkjs';

export interface SanityRefResolver {
  // sanity _type names to match, 'reference' for standard refs
  refTypes: string[];
  // Function to generate groq query given the refId as a string param
  queryFn: (refId: string) => string;
}

export type QueryFetchFn = {<T = any>(query: string): Promise<T>};

/**
 * This function will mutate reference-objects:
 * The keys of a reference-object will be deleted and the keys of the reference-
 * document will be added.
 * eg:
 * { _type: 'reference', _ref: 'abc' }
 * becomes:
 * { _type: 'document', _id: 'abc', ...allOtherDocumentProps }
 * CREDIT: https://github.com/sanity-io/GROQ/issues/21#issuecomment-862284356
 */
export default async function resolveReferences(
  input: unknown,
  queryFetchFn: QueryFetchFn,
  resolvers: SanityRefResolver[],
  defaultResolver: SanityRefResolver,
  resolvedIds: string[] = [],
) {
  if (!input) {
    return; // TODO: this probshouldn't happen, so we should do some logging here.
  }

  const walker = new AsyncWalkBuilder();
  walker.withCallback({
    filters: [(node) => node?.val?._ref && node?.val?._type],
    callback: async (node) => {
      const resolver =
        resolvers.find((r) => r.refTypes.includes(node.val?._type)) ||
        defaultResolver;
      // get refId and ensure we haven't been here (more than once) before
      const refId = node.val._ref;
      if (resolvedIds.filter((id) => id == refId).length > 1) {
        const ids = `[${resolvedIds.concat(refId).join(',')}]`;
        throw new Error(
          `Ran into an infinite loop of references, please investigate the following sanity document order: ${ids}`,
        );
      }
      // TODO: forego queryFn for query + params as it's more portable and (sanity) idiomatic
      const query = resolver.queryFn(refId); // build query as string
      let doc = await queryFetchFn(query); // doc will be mutated, a lot.

      await resolveReferences(
        doc,
        queryFetchFn,
        resolvers,
        defaultResolver,
        resolvedIds.concat(refId),
      );

      if (doc != null) {
        // replace all of original node with props from doc
        Object.keys(node.val).forEach((key) => delete node.val[key]);
        Object.keys(doc).forEach((key) => (node.val[key] = doc[key]));
      }
    },
  });

  // do the thing, side effects abound.
  await walker.walk(input);
  return input;
}

import imageUrlBuilder from '@sanity/image-url';
import client from '@data/sanityClient';
import {ResolvedImageData} from '@data/types';

const getImageUrl = (image, fit, width = null, height = null): string => {
  if (image) {
    const urlBuilder = imageUrlBuilder(client);
    const url = urlBuilder.image(image).fit(fit);
    if (fit == 'crop') return url.width(width).height(height).url();
    return url.url();
  }
  return '';
};

export const getAspectRatioFromImageData = (
  img: ResolvedImageData,
  opts?: {ignoreCrop?: boolean},
) => {
  if (img.crop && !opts.ignoreCrop) {
    // crops are specified as a fraction of total width or height from named edge
    const w = Math.abs(1 - img.crop.right - img.crop.left);
    const h = Math.abs(1 - img.crop.bottom - img.crop.top);
    return w / h;
  }
  const w = img.asset.metadata.dimensions.width;
  const h = img.asset.metadata.dimensions.height;
  return w / h;
};

export default getImageUrl;

import {PtEmbed as PtEmbedData} from '@data/types';
import {UnwrappedGenericEmbed} from '@components/GenericEmbed';
import styled from 'styled-components';

const StyledFullWidthLogic = styled.span`
  ${(props) =>
    props.fullWidth
      ? `iframe {
    width: 100%;
    height: calc(100vw / 1.77);
    }`
      : `@media (max-width: 500px) {
          iframe {
            width: 100%;
            height: calc(100vw / 1.77);
          }
        }`}
`;
const PtEmbed = (props: {node: PtEmbedData}) => (
  <StyledFullWidthLogic
    fullWidth={
      props.node.fullWidth == undefined || null || false ? false : true
    }
  >
    <UnwrappedGenericEmbed
      markup={props.node.markup || ''}
      caption={props.node.caption}
    />
  </StyledFullWidthLogic>
);

export default PtEmbed;

import cfg from '../../config';
import resolveReferences from './resolveReferences';
import type {SanityRefResolver, QueryFetchFn} from './resolveReferences';
import {createClient} from 'next-sanity';
import log from '@util/logging';

const queryCache = new Map();
const cacheStats = {
  cached: 0,
  served: 0,
};

process.addListener('beforeExit', () => {
  let c = console;
  c.log(`Query Cache Stats:
  cached: ${cacheStats.cached}
  served: ${cacheStats.served}
  `);
});

export const sanityConfig = {
  dataset: cfg.sanity.dataset,
  projectId: cfg.sanity.projectId || 'empty',
  apiVersion: '2021-10-21',
  useCdn: cfg.prod,
};

// Set up the client for fetching data in the getProps page functions
export const publicClient = createClient(sanityConfig);

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...sanityConfig,
  useCdn: false,
  token: cfg.sanity.token,
});

export const getClient = (usePreview) =>
  usePreview ? previewClient : publicClient;

// && !(_id in path('drafts.**'))

// TODO: switch to param $id in query vs queryFn - more params to ba added as needed
// TODO: put resolvers somewhere more obvious than here
const defaultResolver: SanityRefResolver = {
  refTypes: [], // TODO: clean up how this is done
  queryFn: (refId) => `*[_id == '${refId}']{...}[0] `,
};

const pageInfoResolver: SanityRefResolver = {
  refTypes: ['pageInfo'],
  queryFn: (refId) =>
    `*[_id == '${refId}']{openGraphImage, slug, title, description, category}[0]`, ///////////// TODO: add ordering to always get draft
};

const pageLinkResolver: SanityRefResolver = {
  refTypes: ['pageLink'],
  queryFn: (refId) => `
  *[_id == '${refId}'][0]{
    "result": select(
      _type == "event" =>  @.slug{..., "current": "events/" + current, "hardLoad": ^.hardLoad},
    _type == "news" => @.slug{..., "current": "news/" + current, "hardLoad": ^.hardLoad},
    _type == "staff" => @.slug{..., "current": "company/leadership/meet-our-leaders/" + current, "hardLoad": ^.hardLoad},
    _type == "course" => @.slug{..., "current": "education-research/training/cme-courses/" + current, "hardLoad": ^.hardLoad},
    _type == "resource" => @.slug{..., "current": "education-research/resources/" + current, "hardLoad": ^.hardLoad},
    @.slug{..., "hardLoad": ^.hardLoad},
    )
    }.result
  `,
};

const articleInfoResolver: SanityRefResolver = {
  refTypes: ['articleInfo'],
  queryFn: (refId) => `*[_id == '${refId}']{
    _type,
    'image': coalesce(image, mainImage),
    'date': coalesce(publishedAt, eventStart),
    'headline': coalesce(title, name),
    'description': shortDescription,
    'slug': slug
  }[0] `,
};

//*[_type == 'division' && _id == '0738087d-a67f-4d0e-ace6-2e63cf60af6f' ]{
// ...,
// "locationsList": *[_type== 'location' && references(^._id)]
//}
const divisionInfoResolver: SanityRefResolver = {
  refTypes: ['divisionInfo'],
  queryFn: (refId) => `*[_type == 'division' && _id == '${refId}' ]{ 
    ...,
    "locationsList": *[_type== 'location' && references(^._id)]
   }[0]`,
};

const resolvers = [
  pageInfoResolver,
  pageLinkResolver,
  articleInfoResolver,
  divisionInfoResolver,
];

export const bigFetch = async (query: string, preview?: boolean) => {
  try {
    const client = getClient(!!preview); // possibly get preview-enabled client

    const fetchFn = async (q) => {
      if (queryCache.has(q)) {
        cacheStats.served++;
        return Promise.resolve(queryCache.get(q));
      } else {
        return client.fetch(q).then((results) => {
          queryCache.set(q, results);
          cacheStats.cached++;
          return results;
        });
      }
    };

    const results = await fetchFn(query);

    // ⚠️ mutates `reults` in-place ⚠️
    await resolveReferences(results, fetchFn, resolvers, defaultResolver);
    return results;
  } catch (e) {
    log.err(e);
  }
};

export default publicClient;

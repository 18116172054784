import styled from 'styled-components';
import {space, font} from '@design/templateFns';

const StyledList = styled.ul`
  // normal spacing
  padding-left: ${space(4)};
  & > li {
    /* padding: 0 0.1em; // inter-item spacing */
    ${font('body-sm-regular')}
  }
`;

const RenderList = (props) => {
  const {type} = props;
  const listType = type === 'bullet' ? 'ul' : 'ol';
  return <StyledList as={listType} {...props} />;
};

export default RenderList;

import {PtImage as PtImageData, ResolvedImageData} from '@data/types';
import {getAspectRatioFromImageData} from '@util/images';
import styled from 'styled-components';
import {mq} from '@theme/query';
import {fontStack} from '@theme/fn';
import {AspectBox} from '@components/Layout';
import ArcImg from '@components/ArcImg';
import log from '@util/logging';

type _styleProps = {
  align?: PtImageData['align'];
  wrap?: PtImageData['wrap'];
  widthName?: PtImageData['width'];
};

const widthsMap: {
  [key in _styleProps['widthName']]: string[];
} = {
  full: ['100%', '100%', '100%'],
  large: ['80%', '80%', ' 80%'],
  medium: ['66%', '66%', '66%'],
  small: ['50%', '50%', '50%'],
};

const widthFn = ({widthName}: _styleProps) => {
  return mq({width: widthsMap[widthName]});
};

const alignmentFn = ({align, wrap}: _styleProps) => {
  if (align === 'center') {
    return {
      margin: '0 auto !important',
    };
  }
  if (wrap) {
    return align === 'right'
      ? {
          float: 'right',
          margin: '16px 0 16px 16px !important',
        }
      : {
          float: 'left',
          margin: '16px 16px 16px 0 !important',
        };
  }
  return align === 'right'
    ? {margin: '0 0 0 auto !important'}
    : {margin: '0 auto 0 0 !important'};
};

const Wrapper = styled.figure<_styleProps>`
  position: relative;
  // width: 100%;
  & > div:first-child {
  }
  ${mq({margin: ['8px', '16px']})}
  ${widthFn}
  ${alignmentFn}
`;

const Caption = styled.figcaption`
  /* ${fontStack(['xxs-body-regular', 'xs-body-regular'])} */
  ${mq({margin: ['12px 0', '24px 0']})}
`;

const PtImage = ({node}: {node: PtImageData}) => {
  try {
    const styleProps: _styleProps = {
      align: node.align || 'left',
      wrap: node.wrap,
      widthName: node.width || 'large',
    };

    const aspect = getAspectRatioFromImageData(node.image);

    return (
      <Wrapper {...styleProps}>
        <AspectBox ratio={aspect}>
          <ArcImg
            imageData={node.image}
            sizes={widthsMap[styleProps.widthName]}
          />
          {node.caption && <Caption>{node.caption}</Caption>}
        </AspectBox>
      </Wrapper>
    );
  } catch (e) {
    log.error('Could not render ptImage', e);
    return null;
  }
};

export default PtImage;

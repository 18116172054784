import {ReactNode} from 'react';
import styled from 'styled-components';
import {font, fontStack} from '@design/templateFns';
import {mq} from '@design/query';

const blockSpacing = {
  // TODO: derive or import this from a more central context ~awt
  sm: mq({margin: ['8px 0', '8px 0', '8px 0']}),
  md: mq({margin: ['16px 0', '16px 0', '16px 0']}),
  lg: mq({margin: ['36px 0 24px', '48px 0 24px', '72px 0 24px']}),
};

const DebugContainer = styled.pre`
  width: 100%;
  padding: 20px;
  border: dotted 3px pink;
  max-height: 22vh;
  overflow: hidden scroll;
`;

// only the stuff we are interested in
interface propsStyler {
  node?: {
    style?: string;
  };
  children?: ReactNode;
  [index: string]: any; // catchall
}

const BodyLg = styled.div`
  ${fontStack(['body-lg-regular'])};
  ${blockSpacing.lg}
`;
const BodyMd = styled.div`
  ${fontStack(['body-md-regular'])};
  ${blockSpacing.md}
`;
const BodySm = styled.div`
  ${fontStack(['body-sm-regular'])};
  ${blockSpacing.sm}
`;
const Caption = styled.div`
  ${fontStack(['body-sm-regular'])};
  ${blockSpacing.sm}
`;

const H2 = styled.h2`
  ${fontStack(['headline-xl', 'headline-xxl', 'headline-xxxl'])}
  ${blockSpacing.lg}
`;

const H3 = styled.h3`
  ${fontStack(['headline-md', 'headline-xl', 'headline-xxl'])}
  ${blockSpacing.lg}
`;

const H4 = styled.h4`
  ${fontStack(['headline-sm', 'headline-md', 'headline-xl'])}
  ${blockSpacing.lg}
`;

const H5 = styled.h5`
  ${fontStack(['headline-xs', 'headline-sm', 'headline-md'])}
  ${blockSpacing.md}
`;

const H6 = styled.h6`
  ${fontStack(['headline-xs', 'headline-xs', 'headline-xs'])}
  ${blockSpacing.md}
`;

const SubHead = styled.div`
  ${fontStack(['eyebrow-lg'])}
  ${blockSpacing.md}
`;

const SubHeadSm = styled.div`
  ${fontStack(['eyebrow-sm'])}
  ${blockSpacing.md}
`;

// const StyledQuoteSvg = styled.svg`
//   width: 64px;
//   height: 64px;
//   margin-left: -8px;
// `;

// const QuoteIcon = (props) => (
//   <StyledQuoteSvg
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 64 64"
//   >
//     <path
//       d="M35 32v20.1h20V32H41.8c0-7.4 6-13.4 13.4-13.4v-6.7C44.1 11.9 35 20.9 35 32zM9 32v20.1h20V32H15.7c0-7.4 6-13.4 13.5-13.4v-6.7C18 11.9 9 20.9 9 32Z"
//       fill="#71976b"
//     />
//   </StyledQuoteSvg>
// );

// const StyledBlockquote = styled.blockquote`
//   ${fontStack(['xs-body-regular', 'lrg-body-regular'])}
//   font-style: italic;
//   ${blockSpacing.md}
// `;

// const BlockQuote = ({children}: propsStyler) => (
//   <div>
//     <QuoteIcon />
//     <StyledBlockquote>{children}</StyledBlockquote>
//   </div>
// );

const stylers = {
  normal: BodyMd, // remap default normal, it seems a normal style is required
  body_lg: BodyLg,
  body_md: BodyMd, // deprecated - this will come in as normal for new entries
  body_sm: BodySm,
  caption: Caption,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  sub_head: SubHead,
  sub_head_sm: SubHeadSm,
  //blockquote: BlockQuote
};

export default stylers;
